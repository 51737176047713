<template>
    <styled-interface no-padding>
        <template #interface-toolbar-external>
            <dealer-toolbar-global
                :settings-open.sync="settingsSlideoutOpen" />
        </template>

        <template #interface-heading>
            Reporting
        </template>

        <ads-upsell v-if="!dealerHasAdProduct" />

        <template v-else>
            <global-alert />

            <div class="layout  mt-3 mb-4 mx-4">
                <div class="flex">
                    <div
                        v-if="campaignsSelected.length"
                        class="layout wrap">
                        <v-chip
                            v-for="campaign in campaignsSelected.slice(0, 4)"
                            :key="campaign.id"
                            class="chip-item"
                            small
                            color="grey lighten-2">
                            <span>
                                {{ campaign.name }}
                            </span>
                            <v-icon
                                class="ml-2"
                                size="18"
                                color="grey lighten-1"
                                @click="unselectCampaign(campaign)">
                                cancel
                            </v-icon>
                        </v-chip>
                        <a
                            v-if="campaignsSelected.length > 4 && showMoreCampaigns == false"
                            @click.prevent="showMoreCampaigns = true">
                            and {{ campaignsSelected.slice(4, campaignsSelected.length).length }} more
                        </a>
                        <span v-if="showMoreCampaigns">
                            <v-chip
                                v-for="campaign in campaignsSelected.slice(4, campaignsSelected.length)"
                                :key="campaign.id"
                                class="chip-item"
                                small
                                color="grey lighten-2">
                                <span>
                                    {{ campaign.name }}
                                </span>
                                <v-icon
                                    class="ml-2"
                                    size="18"
                                    color="grey lighten-1"
                                    @click="unselectCampaign(campaign)">
                                    cancel
                                </v-icon>
                            </v-chip>
                        </span>
                        <a
                            v-if="showMoreCampaigns == true"
                            @click.prevent="showMoreCampaigns = false">
                            show less
                        </a>
                    </div>
                </div>

                <div class="flex shrink">
                    <div class="layout">
                        <action-button
                            v-if="!hideSpend"
                            class="mx-2"
                            icon="pdf"
                            @click="showGenerateReportSlideout = true">
                            Generate
                        </action-button>
                        <action-button
                            class="mx-2"
                            icon="download"
                            @click="exportCSV()">
                            Export
                        </action-button>
                        <action-button
                            class="mx-2"
                            icon="outbound-link"
                            @click="openAdAccount()">
                            Ad Account
                        </action-button>
                        <action-button
                            v-if="!userIsClient"
                            class="mx-2"
                            icon="cog"
                            @click="settingsSlideoutOpen = true">
                            Settings
                        </action-button>
                    </div>
                </div>
            </div>

            <v-alert
                type="error"
                :value="!metricTotals && !metricsLoading"
                class="mb-4 mx-4">
                There is no advertising data available matching the selected criteria
            </v-alert>

            <div id="offlineEventsTarget" />

            <metric-funnel
                v-if="hasInsights"
                class="mx-4"
                @show-info="showMetricInfo = true" />

            <offline-events-card
                v-if="!loadingAccountInsights && metricTotals"
                :class="{
                    'mx-4': true,
                    'flash-on-load--success': highlightOfflineEvents,
                    'no-offline-events': totalOfflineEvents === 0
                }"
                :date-range="dateRange"
                :dealer-id="currentDealerId"
                :metric-totals="metricTotals"
                @update:total-events="totalOfflineEvents = $event" />

            <generate-report-slideout
                v-model="showGenerateReportSlideout"
                :template-id="FACEBOOK_REPORT_ID" />

            <styled-card
                :class="{
                    'mb-4 mx-4 gray-icons': true,
                    'page-break-before': totalOfflineEvents > 0
                }">
                <template #heading>
                    Meta Ads Overall Stats
                </template>

                <template #action-buttons>
                    <action-button
                        class="mx-2"
                        icon="download"
                        @click="exportOverallStatsCSV()">
                        Export
                    </action-button>
                </template>

                <loader
                    v-if="loadingIntervalInsights"
                    class="py-5 bb-1-loblolly" />
                <div
                    v-else-if="metricsChartData.length"
                    class="px-5 pb-5 pt-4 bb-1-loblolly advertising-graph">
                    <advertising-metrics-chart
                        :select-campaigns="campaignsSelected"
                        :date-range="dateRange"
                        :dealer-id="currentDealerId"
                        :left-metric="leftSelectMetric"
                        :right-metric="rightSelectMetric"
                        :metrics-chart-data="metricsChartData"
                        @update="updateMetric" />
                </div>

                <loader
                    v-if="loadingAccountInsights"
                    class="mb-5" />

                <v-layout
                    v-else-if="metricTotals"
                    row
                    wrap
                    class="metric-items pa-5">
                    <v-flex
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="REACH"
                            :metric-string="metricTotals.reach.formatted"
                            :tool-tip-message="$t('metrics.reach')"
                            :class="{'select-left': leftSelectMetric === 'reach', 'select-right': rightSelectMetric === 'reach'}"
                            @click.native="clickMetric('reach')" />
                    </v-flex>
                    <v-flex
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="IMPRESSIONS"
                            :metric-string="metricTotals.impressions.formatted"
                            :tool-tip-message="$t('metrics.impressions')"
                            :class="{'select-left': leftSelectMetric === 'impressions', 'select-right': rightSelectMetric === 'impressions'}"
                            @click.native="clickMetric('impressions')" />
                    </v-flex>
                    <v-flex
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="CLICKS"
                            :metric-string="metricTotals.clicks.formatted"
                            :tool-tip-message="$t('metrics.clicks')"
                            :class="{'select-left': leftSelectMetric === 'clicks', 'select-right': rightSelectMetric === 'clicks'}"
                            @click.native="clickMetric('clicks')" />
                    </v-flex>
                    <v-flex
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="OUTBOUND CLICKS"
                            :metric-string="metricTotals.outbound_clicks.formatted"
                            :tool-tip-message="$t('metrics.outbound_clicks')"
                            :class="{'select-left': leftSelectMetric === 'outbound_clicks', 'select-right': rightSelectMetric === 'outbound_clicks'}"
                            @click.native="clickMetric('outbound_clicks')" />
                    </v-flex>
                    <v-flex
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="LANDING PAGE VIEWS"
                            :metric-string="metricTotals.landing_page_views.formatted"
                            :tool-tip-message="$t('metrics.landing_page_views')"
                            :class="{'select-left': leftSelectMetric === 'landing_page_views', 'select-right': rightSelectMetric === 'landing_page_views'}"
                            @click.native="clickMetric('landing_page_views')" />
                    </v-flex>
                    <v-flex
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="TOTAL LEADS"
                            :metric-string="metricTotals.leads.formatted"
                            :tool-tip-message="$t('metrics.total_leads')"
                            :class="{'select-left': leftSelectMetric === 'leads', 'select-right': rightSelectMetric === 'leads'}"
                            @click.native="clickMetric('leads')" />
                    </v-flex>
                    <v-flex
                        v-if="canViewOfflinePurchaseAttribution"
                        :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                        class="sm6 xs12">
                        <market-stats
                            title="SALES"
                            :metric-string="metricTotals.offline_purchase.formatted"
                            :tool-tip-message="$t('metrics.offline_purchase')"
                            :class="{'select-left': leftSelectMetric === 'offline_purchase', 'select-right': rightSelectMetric === 'offline_purchase'}"
                            @click.native="clickMetric('leads')" />
                    </v-flex>
                    <template v-if="!hideSpend">
                        <v-flex
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="SPEND"
                                :metric-string="metricTotals.spend.formatted"
                                :tool-tip-message="$t('metrics.spend')"
                                :class="{'select-left': leftSelectMetric === 'spend', 'select-right': rightSelectMetric === 'spend'}"
                                @click.native="clickMetric('spend')" />
                        </v-flex>
                        <v-flex
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="CPM"
                                :metric-string="metricTotals.cpm.formatted"
                                :tool-tip-message="$t('metrics.cpm')"
                                :class="{'select-left': leftSelectMetric === 'cpm', 'select-right': rightSelectMetric === 'cpm'}"
                                @click.native="clickMetric('cpm')" />
                        </v-flex>
                        <v-flex
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="CPC"
                                :metric-string="metricTotals.cost_per_click.formatted"
                                :tool-tip-message="$t('metrics.cpc')"
                                :class="{'select-left': leftSelectMetric === 'cost_per_click', 'select-right': rightSelectMetric === 'cost_per_click'}"
                                @click.native="clickMetric('cost_per_click')" />
                        </v-flex>
                        <v-flex
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="CPOC"
                                :metric-string="metricTotals.cost_per_outbound_click.formatted"
                                :tool-tip-message="$t('metrics.cpoc')"
                                :class="{'select-left': leftSelectMetric === 'cost_per_outbound_click', 'select-right': rightSelectMetric === 'cost_per_outbound_click'}"
                                @click.native="clickMetric('cost_per_outbound_click')" />
                        </v-flex>
                        <v-flex
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="CPLPV"
                                :metric-string="metricTotals.cost_per_landing_page_view.formatted"
                                :tool-tip-message="$t('metrics.cplpv')"
                                :class="{'select-left': leftSelectMetric === 'cost_per_landing_page_view', 'select-right': rightSelectMetric === 'cost_per_landing_page_view'}"
                                @click.native="clickMetric('cost_per_landing_page_view')" />
                        </v-flex>
                        <v-flex
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="CPL"
                                :metric-string="metricTotals.cost_per_lead.formatted"
                                :tool-tip-message="$t('metrics.cpl')"
                                :class="{'select-left': leftSelectMetric === 'cost_per_lead', 'select-right': rightSelectMetric === 'cost_per_lead'}"
                                @click.native="clickMetric('cost_per_lead')" />
                        </v-flex>
                        <v-flex
                            v-if="canViewOfflinePurchaseAttribution"
                            :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                            class="sm6 xs12">
                            <market-stats
                                title="CPS"
                                :metric-string="metricTotals.cost_per_offline_purchase.formatted"
                                :tool-tip-message="$t('metrics.cost_per_offline_purchase')"
                                :class="{'select-left': leftSelectMetric === 'cost_per_offline_purchase', 'select-right': rightSelectMetric === 'cost_per_offline_purchase'}"
                                @click.native="clickMetric('cost_per_lead')" />
                        </v-flex>
                    </template>
                </v-layout>
            </styled-card>

            <campaign-stats-table />
        </template>
    </styled-interface>
</template>

<script>
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import GlobalAlert from '@/components/globals/GlobalAlert';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import AdsUpsell from '@/components/globals/AdsUpsell';
import MarketStats from '@/components/globals/MarketStats';
import MetricFunnel from './MetricFunnel';
import Loader from '@/components/globals/Loader.vue';
import AdvertisingMetricsChart from './AdvertisingMetricsChart';
import CampaignStatsTable from '@/components/reports/facebook-advertising/CampaignStatsTable';
import OfflineEventsCard from './OfflineEventsCard';
import GenerateReportSlideout from '@/components/report-builder/GenerateReportSlideout';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import { SET_FB_DEALER_CAMPAIGNS_SELECTED } from '@/store/mutation-types';
import { FACEBOOK_REPORT_ID } from '@/helpers/globals';

export default {
    name: 'FacebookAdvertising',
    components: {
        DealerToolbarGlobal,
        GlobalAlert,
        ActionButton,
        AdsUpsell,
        MarketStats,
        Loader,
        MetricFunnel,
        AdvertisingMetricsChart,
        OfflineEventsCard,
        GenerateReportSlideout,
        StyledInterface,
        StyledCard,
        CampaignStatsTable
    },
    title: 'Meta Advertising',
    data() {
        return {
            errors: [],
            toolbarFixed: false,
            toolbarHeight: 0,
            leftSelectMetric: '',
            rightSelectMetric: '',
            compareSelect: 0,
            showMoreCampaigns: false,
            highlightOfflineEvents: false,
            isResetting: false,
            showMetricInfo: false,
            totalOfflineEvents: 0,
            settingsSlideoutOpen: false,
            showGenerateReportSlideout: false,
            FACEBOOK_REPORT_ID
        };
    },
    computed: {
        ...mapGetters([
            'userIsClient',
            'dealerHasAdProduct',
            'dealerAdSpendOverride',
            'dealerFacebookAdAccountUrlFiltered'
        ]),
        ...mapGetters('metrics/facebook/ads', {
            metricsLoading: 'metricsLoading',
            metricTotals: 'metricsTotalsFormatted',
            metricsChartData: 'metricsIntervalsFormatted',
            campaignStatsData: 'metricsCampaignsFormatted',
            hasInsights: 'hasMetrics',
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentDealer: (state) => state.dealer.currentDealer,
            user: (state) => state.user.user,
            dateRange: (state) => state.metrics.dateRange,
            attribution: (state) => state.metrics.attribution,

            // Metric state
            campaigns: (state) => state.metrics.facebook.ads.campaigns,
            campaignsSelected: (state) => state.metrics.facebook.ads.campaignsSelected,
            loadingCampaigns: (state) => state.metrics.facebook.ads.campaignsLoading,
            loadingInsights: (state) => state.metrics.facebook.ads.dealerInsightsLoading,
            loadingAccountInsights: (state) => state.metrics.facebook.ads.metricsTotalsLoading,
            loadingCampaignInsights: (state) => state.metrics.facebook.ads.metricsCampaignsLoading,
            loadingIntervalInsights: (state) => state.metrics.facebook.ads.metricsIntervalsLoading,
        }),
        pageTitle() {
            if (!this.currentDealer) {
                return null;
            }

            const startDate = moment(this.dateRange.startDate).format('ll');
            const endDate = moment(this.dateRange.endDate).format('ll');

            return `Meta Ads Report - ${this.currentDealer.name} - ${startDate} to ${endDate}`;
        },
    },
    watch: {
        pageTitle: {
            handler(value) {
                this.$title = value;
            },
            immediate: true
        },
        currentDealerId() {

            // Watch on the dealer ID since this doesn't show up
            // for non-advertising dealers
            if (this.$refs.toolbar) {
                this.toolbarHeight = this.$refs.toolbar.offsetHeight;
            }
        },
        dealerAdSpendOverride: {
            handler() {
                this.setDefaultMetricSelections();
            },
            immediate: true
        },
        hideSpend() {
            this.setDefaultMetricSelections();
        }
    },
    mounted() {
        if(this.$route.query.view_offline_events) {
            this.$scrollTo(document.getElementById('offlineEventsTarget'), 2, { easing: 'ease-in', offset: 300 });
        }
    },
    activated() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.$title = this.pageTitle;
    },
    deactivated() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        setDefaultMetricSelections() {
            this.leftSelectMetric = 'reach';
            this.rightSelectMetric = this.dealerAdSpendOverride?.type == 'no_spend' ? 'impressions' : 'spend';
        },
        openAdAccount() {
            const url = this.dealerFacebookAdAccountUrlFiltered({
                dateRange: this.dateRange,
                campaigns: this.campaignsSelected.map(campaign => campaign.id)
            });
            window.open(url);
        },
        updateMetric(v) {
            this.leftSelectMetric = v[0];
            this.rightSelectMetric = v[1];
        },
        clickMetric(metric_id) {
            if (this.compareSelect == 0) {
                if (this.rightSelectMetric == metric_id)
                    return;

                this.leftSelectMetric = metric_id;
                this.compareSelect = !this.compareSelect;
            } else {
                if (this.leftSelectMetric == metric_id)
                    return;

                this.rightSelectMetric = metric_id;
                this.compareSelect = !this.compareSelect;
            }
        },

        unselectCampaign(unselectedCampaign) {

            const newCampaigns = this.campaignsSelected.filter(campaign => {
                return campaign.id !== unselectedCampaign.id;
            });

            this.$store.commit(SET_FB_DEALER_CAMPAIGNS_SELECTED, newCampaigns);
        },

        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}.csv`
            };

            const csvExporter = new ExportToCsv(options);
            const exportData = this.campaignStatsData.map(campaign => {
                const newData = {};
                for (let metric in campaign) {
                    newData[metric] = campaign[metric].formatted;
                }
                return newData;
            });

            csvExporter.generateCsv(exportData);
        },

        exportOverallStatsCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}.csv`
            };

            const csvExporter = new ExportToCsv(options);
            const exportData = this.metricsChartData.map(campaign => {
                const newData = {};
                newData['dealer_name'] = this.currentDealer.name;
                for (let metric in campaign) {
                    if(!['campaign_name', 'campaign_id', 'product_id'].includes(metric)) {
                        newData[metric] = campaign[metric].formatted;
                    }
                }
                return newData;
            });

            csvExporter.generateCsv(exportData);
        },

        handleScroll() {
            const elementTarget = this.$refs.toolbar;
            if (!elementTarget) {
                return;
            }
            if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
                this.toolbarFixed = true;
            } else {
                this.toolbarFixed = false;
            }
        }
    }
};
</script>
<style lang="scss">
.toolbar-fixed {
    .v-autocomplete.v-input > .v-input__control > .v-input__slot {
        height: 33px;
        margin-bottom: 0;
    }
    .v-text-field__details {
        display: none;
    }
}

.campaign-select {
    .campaign-selections {
        background-color: $gray;
        color: $white;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        border-radius: 3px;
    }
    input {
        &::placeholder {
            color: $gray !important;
        }
    }
    .v-input--has-selection.v-input--is-focused {
        input {
            margin-left: 10px;
        }
    }
    .v-input--is-dirty:not(.v-input--is-focused),
    .v-input--has-selection:not(.v-input--is-focused) {
        input {
            opacity: 0;
        }
    }
}

</style>
<style lang="scss" scoped>

.toolbar {
    transition: none;
    transform: translateY(0px);
    background-color: transparent;
    .toolbar-heading {
        align-self: start;
        h2 {
            font-size: 1.3rem;
        }
    }
}

.toolbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    padding: 20px;
    background-color: #f7f7f7;
    align-items: flex-end;
    transform: translateY(89px);
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 0 10px rgba(black, 0.5);
    .toolbar-heading {
        display: none;
    }
}

.metric-items {
    .select-left  {
        outline:2px solid #7FDDFE !important;
        outline-offset: -10px;
    }
    @media (min-width: 960px) {
        .custom {
            flex-basis: calc(100%/7) !important;
            flex-grow: 0;
            max-width: calc(100%/7) !important;
        }
    }

    .select-right  {
        outline:2px solid #E350B4 !important;
        outline-offset: -7px;
    }
}

@media print {
    .toolbar-container {
        display: none;
    }
    .advertising-graph-toolbar {
        padding: 0 !important;
    }
    .advertising-graph {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border-bottom: 0 !important;
    }
    .metric-items {
        padding-left: 0 !important;
        padding-right: 0 !important;
        .stats-element {
            outline: none !important;
        }
    }
    .page-break-before {
        page-break-before: always;
    }
    .no-offline-events {
        display: none;
    }
}
</style>

