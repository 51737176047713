<template>
    <div class="pt-3">
        <v-layout
            row
            class="justify-center justify-center align-center">
            <div>
                <v-select
                    v-model="leftSelectedValue"
                    :items="leftSelect"
                    label="Select"
                    outline
                    solo
                    flat
                    small
                    class="left-select metric-select" />
            </div>
            <div class="px-5 text-xs-center">
                VS
            </div>
            <div>
                <v-select
                    v-model="rightSelectedValue"
                    :items="rightSelect"
                    label="Select"
                    outline
                    solo
                    flat
                    small
                    class="right-select metric-select" />
            </div>
        </v-layout>
        <apexchart
            ref="chart"
            chart="line"
            :height="420"
            :options="options"
            :series="series" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        leftMetric: {
            type: String,
            required: true
        },
        rightMetric: {
            type: String,
            required: true
        },
        metricsChartData: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            mediaQueryList: null,
            numberFormats: getNumberFormats(),
            rawData: [],
            loading: true,
            availableSelections: [{
                text: 'REACH',
                value: 'reach'
            },{
                text: 'IMPRESSIONS',
                value: 'impressions'
            },{
                text: 'CLICKS',
                value: 'clicks'
            },{
                text: 'OUTBOUND CLICKS',
                value: 'outbound_clicks'
            },{
                text: 'LANDING PAGE VIEWS',
                value: 'landing_page_views'
            },{
                text: 'TOTAL LEADS',
                value: 'leads'
            },{
                text: 'SALES',
                value: 'offline_purchase',
                tags: ['offline_purchase']
            },{
                text: 'SPEND',
                value: 'spend',
                tags: ['sales', 'spend']
            },{
                text: 'CPM',
                value: 'cpm',
                tags: ['spend']
            },{
                text: 'CPC',
                value: 'cost_per_click',
                tags: ['spend']
            },{
                text: 'CPOC',
                value: 'cost_per_outbound_click',
                tags: ['spend']
            },{
                text: 'CPLPV',
                value: 'cost_per_landing_page_view',
                tags: ['spend']
            },{
                text: 'CPL',
                value: 'cost_per_lead',
                tags: ['spend']
            },{
                text: 'CPS',
                value: 'cost_per_offline_purchase',
                tags: ['offline_purchase', 'spend']
            }],
            leftSelectedValue: 'reach',
            rightSelectedValue: 'impressions',
            options: {
                chart: {
                    id: 'vuechart-example',
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dataLabels: {
                        enabled: true
                    },
                    height: 300
                },
                colors: ['#7FDDFE', '#E350B4'],
                xaxis: {
                    type: 'datetime',
                    //tickAmount: 3,
                    labels: {
                        trim: true,
                        //offsetX: 10,
                        style: {
                            colors: '#8f9ea6'
                        }
                    }
                },
                grid: {
                    strokeDashArray: 2,
                },
                stroke: {
                    width: 1
                },
                markers: {
                    size: 4,
                    colors: ['#FFFFFF', '#FFFFFF'],
                    strokeColors: ['#7FDDFE', '#E350B4'],
                    strokeWidth: 1,
                    fillOpacity: 0
                },
                legend: {
                    show: false
                },
                yaxis: [
                    {
                        decimalsInFloat: 2,
                        labels: {
                            style: {
                                color: '#8f9ea6'
                            }
                        }
                    },
                    {
                        opposite: true,
                        decimalsInFloat: 2,
                        labels: {
                            style: {
                                color: '#8f9ea6'
                            }
                        }
                    },

                ],
            },
            series: [
                {
                    name: 'REACH',
                    data: [
                        []
                    ]
                },
                {
                    name: 'IMPRESSIONS',
                    data: [
                        []
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters('metrics/facebook/ads', {
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        activeSelections() {
            return this.availableSelections.filter(item => {
                if (
                    item.tags?.includes('spend') &&
                    this.hideSpend
                ) {
                    return false;
                }

                if (
                    item.tags?.includes('offline_purchase') &&
                    !this.canViewOfflinePurchaseAttribution
                ) {
                    return false;
                }

                return true;
            });
        },
        leftSelect() {
            return this.activeSelections.map(item => {
                item.disabled = (item.value === this.rightSelectedValue);
                return item;
            });
        },
        rightSelect() {
            return this.activeSelections.map(item => {
                item.disabled = (item.value === this.leftSelectedValue);
                return item;
            });
        }
    },
    watch: {
        'metricsChartData': {
            handler() {
                this.updateLeftSelectData();
                this.updateRightSelectData();
            },
            deep: true,
        },
        canViewOfflinePurchaseAttribution() {
            this.updateLeftSelectData();
            this.updateRightSelectData();
        },
        leftSelectedValue() {
            this.$emit('update', [this.leftSelectedValue, this.rightSelectedValue]);
            this.updateLeftSelectData();
        },
        rightSelectedValue() {
            this.$emit('update', [this.leftSelectedValue, this.rightSelectedValue]);
            this.updateRightSelectData();
        },
        leftMetric(value) {
            this.leftSelectedValue = value;
        },
        rightMetric(value) {
            this.rightSelectedValue = value;
        },
    },
    mounted() {
        this.leftSelectedValue = this.leftMetric;
        this.rightSelectedValue = this.rightMetric;

        this.updateLeftSelectData();
        this.updateRightSelectData();

        if (window.matchMedia) {
            this.mediaQueryList = window.matchMedia('print');
            this.mediaQueryList.addListener(this.handleMediaQueryList.bind(this));
        } else {
            window.addEventListener('beforeprint', this.handleBeforePrint.bind(this));
        }
    },
    destroyed() {
        this.mediaQueryList.removeListener(this.handleMediaQueryList);
        window.removeEventListener('beforeprint', this.handleBeforePrint);
    },

    methods: {
        handleMediaQueryList(mql) {
            if (mql.matches) {
                this.handleBeforePrint();
            }
        },

        handleBeforePrint() {
            // @todo reformat before printing
        },
        updateLeftSelectData() {

            // Retrieve the selected field from the data
            const leftSeriesData = this.metricsChartData.map(item => {
                return [
                    new Date(item.date_start.value),
                    item[this.leftSelectedValue].value
                ];
            });

            // Copy the original series into a new array so we
            // can force a state update in the chart
            const newSeries = [...this.series];
            newSeries[0] = {
                data: leftSeriesData,
                name: this.leftSelect.find(option => option.value == this.leftSelectedValue)?.text
            };

            this.series = newSeries;

            // Use the format specified in the first metric
            if (this.metricsChartData.length) {

                const format = this.metricsChartData[0][this.leftSelectedValue].format;

                const newOptions = { ...this.options };
                newOptions.yaxis[0].labels.formatter = (value) => {
                    return numeral(value).format(this.numberFormats[format]);
                };
                this.options = newOptions;
            }
        },
        updateRightSelectData() {

            // Retrieve the selected field from the data
            const rightSeriesData = this.metricsChartData.map(item => {
                return [
                    new Date(item.date_start.value),
                    item[this.rightSelectedValue].value
                ];
            });

            // Copy the original series into a new array so we
            // can force a state update in the chart
            const newSeries = [...this.series];
            newSeries[1] = {
                data: rightSeriesData,
                name: this.rightSelect.find(option => option.value == this.rightSelectedValue)?.text
            };

            this.series = newSeries;

            // Use the format specified in the first metric
            if (this.metricsChartData.length) {

                const format = this.metricsChartData[0][this.rightSelectedValue].format;

                const newOptions = { ...this.options };
                newOptions.yaxis[1].labels.formatter = (value) => {
                    return numeral(value).format(this.numberFormats[format]);
                };
                this.options = newOptions;
            }
        }
    }
};
</script>
