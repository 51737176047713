var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-card',{staticClass:"mb-4 gray-icons",scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"layout align-center"},[_c('span',[_vm._v(" Offline Performance ")])])]},proxy:true},{key:"action-buttons",fn:function(){return [_c('v-select',{staticClass:"styled-field mr-4",attrs:{"items":_vm.eventTypes,"item-text":"label","item-value":"key","label":"Event Type","return-object":""},model:{value:(_vm.eventType),callback:function ($$v) {_vm.eventType=$$v},expression:"eventType"}}),_c('action-button',{attrs:{"icon":"add","to":{
                name: 'dealer-offline-events',
                params: {
                    dealer_id: _vm.dealerId
                }
            }}},[_vm._v(" Upload Events ")])]},proxy:true}])},[(_vm.error || !_vm.dealerFacebookOfflineEventSetAnnotation)?_c('div',{staticClass:"text-xs-center ma-5"},[(_vm.error)?_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):(!_vm.dealerFacebookOfflineEventSetAnnotation)?_c('div',{staticClass:"red--text"},[_vm._v(" No offline event set has been configured on this account. Please contact support to resolve this issue ")]):_vm._e()]):_vm._e(),(!_vm.hasCurrentEventTypeEvents)?_c('div',{staticClass:"pa-5 text-xs-center"},[(_vm.lastOfflineEventDate)?_c('h3',[_vm._v(" You haven't uploaded "+_vm._s(_vm._f("lowercase")(_vm._f("camelCaseToWords")(_vm.eventType.name)))+" events since "+_vm._s(_vm.lastEvent)+". ")]):_c('h3',[_vm._v(" There is not enough available offline data to calculate "+_vm._s(_vm._f("lowercase")(_vm._f("camelCaseToWords")(_vm.eventType.name)))+" event attribution data. ")]),_c('p',[_vm._v(" Please "),_c('router-link',{attrs:{"to":{
                    name: 'dealer-offline-events',
                    params: {
                        dealer_id: _vm.dealerId
                    }

                }}},[_c('span',[_vm._v("upload "+_vm._s(_vm._f("lowercase")(_vm._f("camelCaseToWords")(_vm.eventType.name)))+" events")])]),_vm._v(" up to "+_vm._s(_vm.endDate)+" to view sales attribution for this period. ")],1),_c('styled-button',{attrs:{"green":"","to":{
                name: 'dealer-offline-events',
                params: {
                    dealer_id: _vm.dealerId
                }
            }}},[_vm._v(" UPLOAD EVENTS ")])],1):_c('div',{staticClass:"pa-5"},[_c('div',{staticClass:"layout row wrap align-end pb-4 px-4"},[_c('div',{staticClass:"flex shrink"},[_c('market-stats',{style:({
                        width: '140px',
                        padding: '0 0 30px'
                    }),attrs:{"title":_vm.totalLabel,"metric":_vm.totalEvents,"decreased-size":""}},[(_vm.loadingEvents)?_c('styled-loader',{staticClass:"mt-2",attrs:{"size":"20"}}):_vm._e()],1)],1),_c('div',{staticClass:"flex grow"},[_c('div',{staticClass:"layout row wrap align-end pb-2"},[_c('div',{staticClass:"flex md2 xs2 text-center pr-2"},[_c('img',{attrs:{"src":"/img/triangle.png"}})]),_c('div',{staticClass:"flex md8 xs8"},[_c('table',{staticClass:"purchase-window"},[_c('thead',[_c('tr',[_c('th'),_c('th',{attrs:{"colspan":"3"}},[_c('h3',[_vm._v("Attribution Window")])])]),_c('tr',[_c('th'),_c('th',{style:({ width: '25%' })},[_vm._v(" 1 DAY ")]),_c('th',{style:({ width: '25%' })},[_vm._v(" 7 DAYS ")]),_c('th',{style:({ width: '25%' })},[_vm._v(" 28 DAYS ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Viewed Ad and did not click")]),_c('td',{class:{
                                            'active-window': _vm.attribution.offline.view == '1d'
                                        }},[_c('span',[_vm._v(_vm._s(_vm.attributionMetrics['1d_view'].formatted))])]),_c('td',{class:{
                                            'active-window': _vm.attribution.offline.view == '7d'
                                        }},[_c('span',[_vm._v(_vm._s(_vm.attributionMetrics['7d_view'].formatted))])]),_c('td',{class:{
                                            'active-window': _vm.attribution.offline.view == '28d'
                                        }},[_c('span',[_vm._v(_vm._s(_vm.attributionMetrics['28d_view'].formatted))])])]),_c('tr',[_c('td',[_vm._v("Viewed and Clicked on Ad")]),_c('td',{class:{
                                            'active-window': _vm.attribution.offline.click == '1d'
                                        }},[_c('span',[_vm._v(_vm._s(_vm.attributionMetrics['1d_click'].formatted))])]),_c('td',{class:{
                                            'active-window': _vm.attribution.offline.click == '7d'
                                        }},[_c('span',[_vm._v(_vm._s(_vm.attributionMetrics['7d_click'].formatted))])]),_c('td',{class:{
                                            'active-window': _vm.attribution.offline.click == '28d'
                                        }},[_c('span',[_vm._v(_vm._s(_vm.attributionMetrics['28d_click'].formatted))])])])])])]),_c('div',{staticClass:"flex md2 xs2 text-center pl-2"},[_c('img',{attrs:{"src":"/img/triangle.png"}})])])]),_c('div',{staticClass:"flex shrink"},[_c('market-stats',{style:({
                        width: '140px',
                        padding: '0 0 20px'
                    }),attrs:{"title":"EVENTS ATTRIBUTED TO META ADS","metric":_vm.totalAttributedEvents,"decreased-size":""}}),(!_vm.hideSpend)?_c('market-stats',{style:({
                        width: '140px',
                        padding: 0
                    }),attrs:{"title":"COST PER","metric-string":_vm.costPerEvent,"decreased-size":""}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }